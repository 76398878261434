import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { TranslateService } from '@ngx-translate/core';
import { JWTTokenService } from './pages/pages/auth/JWTToken.service';
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

const TOKEN_SHARING_CHANNEL = "token-sharing";
const REQUESTING_TOKEN = "requesting-token";
//export let browserRefresh = false;
@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  bc = new BroadcastChannel(TOKEN_SHARING_CHANNEL);
  subscription: Subscription
  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private jwttokenservice: JWTTokenService,
    private router: Router,
  ) {
    //this.bc.postMessage(REQUESTING_TOKEN);
    Settings.defaultLocale = this.localeId;
    this.translate.addLangs(['en', 'fr', 'ar']);
    this.translate.setDefaultLang('en');
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }
    //this.jwttokenservice.getToken()
    this.addBroadcastChannelListener();
    this.bc.postMessage(REQUESTING_TOKEN);
    /*  this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          this.bc.postMessage(REQUESTING_TOKEN);
        }
      })
  */
    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */


    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });
    /**
     * Add your own routes here
     */
    this.navigationService.items = [{
      type: 'subheading',
      label: 'Apps',
      children: [


        {
          type: 'link',
          label: 'aa',
          route: '/apps/aa',
          active: false
        },
        {
          type: 'link',
          label: 'Admin',
          route: '/apps/admin',
          icon: 'mat:person',
          active: false
        },
        {
          type: 'link',
          label: 'Inscriptions',
          route: '/apps/information-inscription',
          icon: 'mat:assignment',
          active: false
        },
        {
          type: 'link',
          label: 'Dashboard',
          route: '/apps/Dashboard',
          icon: 'mat:dashboard',
          active: false
        },


        {
          type: 'link',
          label: 'Profile',
          route: '/apps/profile',
          icon: 'mat:account_circle',
          active: false
        },



        {
          type: 'link',
          label: 'Liste Inscriptions',
          route: '/apps/liste-inscription',
          icon: 'mat:list',
          active: false
        },

        {
          type: 'link',
          label: 'ListeInscriptions',
          route: '/apps/liste-inscription',
          icon: 'mat:people',
          active: false
        },
        {
          type: 'link',
          label: 'Soumission',
          route: '/apps/soumission',
          icon: 'mat:send',
          active: false
        },
        {
          type: 'link',
          label: 'Hébergement',
          route: '/apps/hebergement',
          icon: 'mat:home',  // Changez 'mat:send' par l'icône que vous préférez, ici 'mat:home'         active:false
        },
        /* {         
         type: 'link',
        label: 'Comission',
        route: '/apps/comission',
        icon: 'mat:list',
        active:false
      }, */
        {
          type: 'link',
          label: 'Packs',
          route: '/apps/packs',
          icon: 'mat:archive',
          active: false
        },

        {
          type: 'link',
          label: 'Inscriptions',
          route: '/apps/information-inscription',
          icon: 'mat:list',
          active: false
        },


        {
          type: 'link',
          label: 'Ateliers',
          route: 'atelier-table2',
          icon: 'mat:group',
          active: false
        },
        {
          type: 'link',
          label: 'Groupe des ateliers',
          route: '/apps/groupe',
          icon: 'mat:group',
          active: false
        },
        {
          type: 'link',
          label: 'Laboratoire',
          route: '/apps/labo',
          icon: 'mat:science',
          active: false
        },
        {
          type: 'link',
          label: 'CodePromo',
          route: '/apps/code-promo',
          icon: 'mat:local_offer',
          active: false
        },
        {
          type: 'link',
          label: 'Publicité',
          route: '/apps/image-pup',
          icon: 'mat:campaign',
          active: false
        },
        {
          type: 'link',
          label: 'Remarque',
          route: '/apps/remarque',
          icon: 'mat:note',
          active: false
        }
        ,
      
        {
          type: 'link',
          label: 'Programme',
          route: '/apps/programme2',
          icon: 'mat:event',
          active: false
        },
        {
          type: 'link',
          label: 'Tarifs',
          route: '/apps/tarifs',
          icon: 'mat:attach_money',
          active: false
        },

        {
          type: 'link',
          label: 'Packs',
          route: '/apps/packs',
          icon: 'mat:list',
          active: false
        },


        /*   {
            type: 'link',
            label: 'Soumissions',
            route: '/apps/soumission',
            icon: 'mat:file_upload',
            active: false
          }, */

        /* {         
         type: 'link',
        label: 'Comission',
        route: '/apps/comission',
        icon: 'mat:list',
        active:false
      }, */




        // {
        //   type: 'link',
        //   label: 'Inscriptions',
        //   route: '/apps/inscription',
        //   icon: 'mat:list',
        //   active:false
        // },

        {
          type: 'link',
          label: 'Registration History',
          route: '/apps/historique-inscription',
          icon: 'mat:list',
          active: false
        },


        /*  {
           type: 'link',
           label: 'Conferenciers',
           route: '/apps/conferencier',
           icon: 'mat:list',
           active:false
         }, */

        {
          type: 'link',
          label: 'Speakers',
          route: '/apps/speakers-table',
          icon: 'mat:assignment',
          active: false
        },

        {
          type: 'link',
          label: 'Conferences',
          route: '/apps/conference',
          icon: 'mat:assignment',
          active: false
        },


        {
          type: 'link',
          label: 'Télecharger Images',
          route: '/apps/upload-image',
          icon: 'mat:image',
          active: false
        },

        {
          type: 'link',
          label: 'Agents',
          route: '/apps/table-agent',
          icon: 'mat:assignment',
          active: false
        },


        {
          type: 'link',
          label: 'Profil',
          route: '/apps/profil',
          icon: 'mat:assignment',
          active: false
        },
        /*   {
            type: 'link',
            label: 'Participants',
            route: '/apps/participant',
            icon: 'mat:assignment',
            active: false
          }, */
        {
          type: 'link',
          label: 'Presences',
          route: '/apps/presence',
          icon: 'mat:event_available',
          active: false
        },
        /*  {
           type: 'link',
           label: 'Workshops',
           route: '/apps/workshop-table',
           icon: 'mat:assignment',
           active: false
         }, */
        {
          type: 'link',
          label: 'Certificates',
          route: '/apps/attestation',
          icon: 'mat:assignment',
          active: false
        },


        {
          type: 'link',
          label: 'Quiz',
          route: '/apps/quiz',
          icon: 'mat:assignment',
          active: false
        },
        /*  {
           type: 'link',
           label: 'Test',
           route: '/apps/test_json',
           icon: 'mat:assignment',
           active: false
         }, */
        /*  {
           type: 'link',
           label: 'Workshops',
           route: '/apps/atelier-table',
           icon: 'mat:assignment',
           active: false
         }, */

        {
          type: 'link',
          label: 'Badges',
          route: '/apps/badge',
          icon: 'mat:verified',
          active: false
        },


        {
          type: 'link',
          label: 'Events Table',
          route: '/apps/manage-events',
          icon: 'mat:calendar_today',
          active: false
        },
        {
          type: 'link',
          label: 'Events',
          route: '/apps/event-table',
          icon: 'mat:calendar_today',
          active: false
        },
        //  {
        //   type: 'link',
        //   label: 'Events',
        //   route: '/apps/event-table',
        //   icon: 'mat:calendar_today',
        //   active: false
        // },
      ]
    },

    ];

  }
  private addBroadcastChannelListener() {
    this.bc.addEventListener("message", (event) => {
      if (event.data === REQUESTING_TOKEN) {
        new BroadcastChannel(TOKEN_SHARING_CHANNEL).postMessage({
          accessToken: this.jwttokenservice.getToken(),
        });
      } else {
        const { accessToken } = event.data;
        accessToken && this.jwttokenservice.setToken(accessToken.token ? accessToken.token : accessToken);
      }
    });
  }
}
