import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger60ms } from 'src/@vex/animations/stagger.animation';
import { ColorSchemeName } from 'src/@vex/config/colorSchemeName';
import { VexConfigName } from 'src/@vex/config/config-name.model';
import { ConfigService } from 'src/@vex/config/config.service';
import { defaultRoundedButtonBorderRadius } from 'src/@vex/config/constants';
import { VexConfig } from 'src/@vex/config/vex-config.interface';
import { CSSValue } from 'src/@vex/interfaces/css-value.type';
import { LayoutService } from 'src/@vex/services/layout.service';
import { isNil } from 'src/@vex/utils/isNil';
import { DashboardSendingDataService } from 'src/app/pages/apps/dashboard/dashboard-sending-data.service';
import { ColorVariable, colorVariables } from '../config-panel/color-variables';
import { ConfigPanelFilterService } from './config-panel-filter.service';

@Component({
  selector: 'vex-config-panel-filter',
  templateUrl: './config-panel-filter.component.html',
  styleUrls: ['./config-panel-filter.component.scss'],
  animations: [
    stagger60ms,
    fadeInUp400ms
  ]
})
export class ConfigPanelFilterComponent implements OnInit {
  layoutCtrl = new UntypedFormControl('Annuel');
  @ViewChild(MatDatepicker) picker;
  Years: any[] = ["2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
  Months: any[] = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aôut", "Septembre", "Octobre", "Novembre", "Décembre"]
  staticList: any[];
  filteredStaticList$: Observable<any[]>;
  staticListCtrl: UntypedFormControl;
  Cities: any[];
  Hotels: any[]
  Agences: any[]
  Groupe: any[]
  filteredGroupeList$: Observable<any[]>;
  filteredHotelsList$: Observable<any[]>;
  filteredAgencesList$: Observable<any[]>;

  filteredCitiesList$: Observable<any[]>;
  CitiesListCtrl: UntypedFormControl;
  HotelsListCtrl: UntypedFormControl;
  AgencesListCtrl: UntypedFormControl;

  GroupesListCtrl: UntypedFormControl;

  filtrenom: UntypedFormControl
  configs: VexConfig[] = this.configService.configs;
  colorVariables: Record<string, ColorVariable> = colorVariables;
  FiltreNom
  config$: Observable<VexConfig> = this.configService.config$;
  HotelFiltrer
  isRTL$: Observable<boolean> = this.config$.pipe(
    map(config => config.direction === 'rtl')
  );

  isDark$: Observable<boolean> = this.config$.pipe(
    map(config => config.style.colorScheme === ColorSchemeName.dark)
  );

  borderRadius$: Observable<number> = this.config$.pipe(
    map(config => config.style.borderRadius.value)
  );

  ConfigName = VexConfigName;
  ColorSchemeName = ColorSchemeName;
  selectedColor = colorVariables.blue;
  selectedmap
  selectedcity
  selectedhotel
  selectedgroupe
  selectedagence
  roundedCornerValues: CSSValue[] = [
    {
      value: 0,
      unit: 'rem'
    },
    {
      value: 0.25,
      unit: 'rem'
    },
    {
      value: 0.5,
      unit: 'rem'
    },
    {
      value: 0.75,
      unit: 'rem'
    },
    {
      value: 1,
      unit: 'rem'
    },
    {
      value: 1.25,
      unit: 'rem'
    },
    {
      value: 1.5,
      unit: 'rem'
    },
    {
      value: 1.75,
      unit: 'rem'
    }
  ];

  roundedButtonValue: CSSValue = defaultRoundedButtonBorderRadius;
  selectedField = [];
  selectedFieldcity = [];
  selectedFieldgroupe = [];

  FilterType: string
  date = new FormControl();

  @Input() ResultSearch: any
  constructor(private configService: ConfigService,
    private layoutService: LayoutService,
    private ConfigPanelFilterService: ConfigPanelFilterService,
    private router: Router,
    private DashboardSendData: DashboardSendingDataService,
    public datepipe: DatePipe,
    @Inject(DOCUMENT) private document: Document) {

  }
  ngOnInit() {

    console.log(this.router.url)
    if (this.router.url == "/apps/searching") {

      this.FilterType = "searching"
    }
    else
      this.FilterType = "dashboard"

    this.staticListCtrl = new UntypedFormControl();
    this.CitiesListCtrl = new UntypedFormControl();
    this.HotelsListCtrl = new UntypedFormControl();
    this.GroupesListCtrl = new UntypedFormControl();
    this.AgencesListCtrl = new UntypedFormControl();
    this.filtrenom = new UntypedFormControl();
    this.GetCountries()
    this.GetCities(null)
    this.GetHotels(null)
    this.GetGroups()
    this.GetAgences(null)

  }
  monthSelected(event) {
    console.log("event : ", event)
    console.log("this.datepipe.transform(new Date(event), 'yyyy-MM-dd': ", new Date(event).getFullYear())
    const ctrlValue = this.date.value;
    console.log("ctrlValue: ", ctrlValue)
    ctrlValue.year(event.year());
    this.date.setValue(ctrlValue);
    // this.date.setValue(event);
    this.picker.close();
  }
  setConfig(layout: VexConfigName, colorScheme: ColorSchemeName): void {
    this.configService.setConfig(layout);
    this.configService.updateConfig({
      style: {
        colorScheme
      }
    });
  }

  selectColor(color: ColorVariable): void {
    this.selectedColor = color;
    this.configService.updateConfig({
      style: {
        colors: {
          primary: {
            default: color.default,
            contrast: color.contrast
          }
        }
      }
    });
  }
  isSelectedColor(color: ColorVariable): boolean {
    return color === this.selectedColor;
  }
  SelectedYear(event) {
    console.log("event year: ", event)
    this.DashboardSendData.UpdateYear(event)
  }
  SelectedMonth(event) {
    console.log("event month: ", event)
    this.DashboardSendData.UpdateMonth(event)
  }
  onValChange(event) {
    console.log("event: ", event)
    this.DashboardSendData.UpdateAffichage(event)
  }
  enableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.dark
      }
    });
  }

  disableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.default
      }
    });
  }

  layoutRTLChange(change: MatSlideToggleChange): void {
    this.configService.updateConfig({
      direction: change.checked ? 'rtl' : 'ltr'
    });
  }

  toolbarPositionChange(change: MatRadioChange): void {
    this.configService.updateConfig({
      toolbar: {
        fixed: change.value === 'fixed'
      }
    });
  }

  footerVisibleChange(change: MatSlideToggleChange): void {
    this.configService.updateConfig({
      footer: {
        visible: change.checked
      }
    });
  }

  footerPositionChange(change: MatRadioChange): void {
    this.configService.updateConfig({
      footer: {
        fixed: change.value === 'fixed'
      }
    });
  }

  isSelectedBorderRadius(borderRadius: CSSValue, config: VexConfig): boolean {
    return borderRadius.value === config.style.borderRadius.value && borderRadius.unit === config.style.borderRadius.unit;
  }

  selectBorderRadius(borderRadius: CSSValue): void {
    this.configService.updateConfig({
      style: {
        borderRadius: borderRadius
      }
    });
  }

  isSelectedButtonStyle(buttonStyle: CSSValue | undefined, config: VexConfig): boolean {
    if (isNil(config.style.button.borderRadius) && isNil(buttonStyle)) {
      return true;
    }

    return buttonStyle?.value === config.style.button.borderRadius?.value;
  }

  selectButtonStyle(borderRadius: CSSValue | undefined): void {
    this.configService.updateConfig({
      style: {
        button: {
          borderRadius: borderRadius
        }
      }
    });
  }
  GetCountries() {
    this.ConfigPanelFilterService.getstaticcountrylist().subscribe(response => {
      console.log("responsecountries: ", response)
      if (!response.error) {
        this.staticList = response.countries
        this.filteredStaticList$ = this.staticListCtrl.valueChanges.pipe(
          startWith(''),
          map(destination => destination ? this.filterstaticList(destination) : this.staticList.slice())
        );
      }
      console.log("this.filteredStaticList$: ", this.filteredStaticList$)
    })
  }

  
  GetCities(id) {
    console.log("id: ", id)
    if (id != null) {
      console.log(id != null)
      this.ConfigPanelFilterService.getcitycountrieslist(id).subscribe(response => {
        console.log("responsecities: ", response)
        if (!response.error) {
          this.Cities = response.cities
          this.filteredCitiesList$ = this.CitiesListCtrl.valueChanges.pipe(
            startWith(''),
            map(destination1 => destination1 ? this.filtercitiesList(destination1) : this.Cities.slice())
          );
        }
      })
    }
    else {
      this.ConfigPanelFilterService.getstaticcitylist().subscribe(response => {
        console.log("responsecities: ", response)
        if (!response.error) {
          this.Cities = response.cities
          console.log("this.Cities: ", this.Cities)
          this.filteredCitiesList$ = this.CitiesListCtrl.valueChanges.pipe(
            startWith(''),
            map(destination1 => destination1 ? this.filtercitiesList(destination1) : this.Cities.slice())
          );
        }
        console.log("this.filteredCitiesList$: ", this.filteredCitiesList$)

      })

    }
  }
  
  GetHotels(id) {
    if (id != null) {
      this.ConfigPanelFilterService.gethotelcitylist(id).subscribe(response => {
        console.log("responsehotels: ", response)
        if (!response.error) {
          this.Hotels = response.hotels
          this.filteredHotelsList$ = this.HotelsListCtrl.valueChanges.pipe(
            startWith(''),
            map(destination2 => destination2 ? this.filtercitiesList(destination2) : this.Hotels.slice())
          );
        }
      })
    } else {
      this.ConfigPanelFilterService.getstatichotellist().subscribe(response => {
        console.log("responsehotels: ", response)
        if (!response.error) {
          this.Hotels = response.hotels
          console.log("this.Cities: ", this.Hotels)
          this.filteredHotelsList$ = this.HotelsListCtrl.valueChanges.pipe(
            startWith(''),
            map(destination2 => destination2 ? this.filterhotelsList(destination2) : this.Hotels.slice())
          );
        }
      })
    }
  }

  GetAgences(id) {
    if (id != null) {
      this.ConfigPanelFilterService.ListAgencesByGroup(id).subscribe(response => {
        console.log("responseagences: ", response)
        if (!response.error) {
          this.Agences = response
          this.filteredAgencesList$ = this.AgencesListCtrl.valueChanges.pipe(
            startWith(''),
            map(destination3 => destination3 ? this.filteragenceList(destination3) : this.Agences.slice())
          );
        }
      })
    }
    else {
      console.log("else")
      this.ConfigPanelFilterService.ListAgences().subscribe(response => {
        console.log("responseagences: ", response)
        if (!response.error) {
          this.Agences = response
          this.filteredAgencesList$ = this.AgencesListCtrl.valueChanges.pipe(
            startWith(''),
            map(destination3 => destination3 ? this.filteragenceList(destination3) : this.Agences.slice())
          );
        }
      })
    }
  }

  GetGroups() {

    this.ConfigPanelFilterService.getgroupelist().subscribe(response => {
      console.log("responsegroupe: ", response)
      if (!response.error) {
        this.Groupe = response
        console.log("this.Groupe: ", this.Groupe)
        this.filteredGroupeList$ = this.GroupesListCtrl.valueChanges.pipe(
          startWith(''),
          map(destination3 => destination3 ? this.filtergroupeList(destination3) : this.Groupe.slice())
        );
      }
    })
  }
  filterstaticList(name) {
    return this.staticList.filter(destination =>
      destination.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
  }
  filtercitiesList(name) {
    return this.Cities.filter(destination =>
      destination.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
  }
  filtergroupeList(name) {
    return this.Groupe.filter(destination =>
      destination.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
  }
  filteragenceList(name) {
    return this.Agences.filter(destination =>
      destination.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
  }
  filterhotelsList(name) {
    return this.Hotels.filter(destination =>
      destination.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
  }
  onChangeDestination(selected) {
    this.selectedmap = this.staticList.find(e => e.name === selected)
    console.log("selected: ", this.selectedmap)
    this.DashboardSendData.UpdateCountry(this.selectedmap)
    this.GetCities(this.selectedmap.id)
  }
  
  onChangecity(selected) {
    this.selectedcity = this.Cities.find(e => e.name === selected)
    console.log("selected: ", this.selectedcity.id)
    this.DashboardSendData.UpdateCity(this.selectedcity)
    this.GetHotels(this.selectedcity.id)
  }
  onChangehotel(selected) {
    this.selectedhotel = this.Hotels.find(e => e.name === selected)
    console.log("selected: ", this.selectedhotel.id)
    this.DashboardSendData.UpdateHotel(this.selectedhotel)
    //this.GetHotels(this.selectedcity.id)

  }

  onChangegroupe(selected) {
    this.selectedgroupe = this.Groupe.find(e => e.name === selected)
    console.log("selected: ", this.selectedgroupe.id)
    this.DashboardSendData.UpdateGroupe(this.selectedgroupe)
    this.GetAgences(this.selectedgroupe.id)

  }
  onChangeagence(selected) {
    this.selectedagence = this.Agences.find(e => e.name === selected)
    console.log("selected: ", this.selectedagence.id)
    this.DashboardSendData.UpdateAgence(this.selectedagence)


  }
  onEnter(keyword) {
    console.log("enter: ", keyword)
    console.log("FiltreNom: ", (<HTMLInputElement>document.getElementById("keyword")).value)
    if ((<HTMLInputElement>document.getElementById("keyword")).value != "") {
      //this.ResultSearch.source.value.HotelsSearch
      this.HotelFiltrer = this.ResultSearch.source.value.HotelsSearch.Hotels.filter(hotel => ((hotel.Title).toLowerCase()).search(((<HTMLInputElement>document.getElementById("keyword")).value).toLowerCase()) != -1)
      console.log("this.HotelFiltrer: ", this.HotelFiltrer)

    }
  }
  FiltreRating(event, rate) {
    console.log("event: ", event, "rate: ", rate)
    this.HotelFiltrer = this.ResultSearch.source.value.HotelsSearch.Hotels.filter(hotel => (hotel.Rating == rate))
    console.log("this.HotelFiltrer: ", this.HotelFiltrer)
  }
  FiltreBoarding(event, boarding) {
    console.log("event: ", event, "rate: ", boarding)
    this.HotelFiltrer = this.ResultSearch.source.value.HotelsSearch.Hotels.filter(hotel => {
      console.log("hotel: ", hotel, hotel.Rooms[0].Room)
      for (let i = 0; i < hotel.Rooms[0].Room.length; i++) {
        console.log("hotel.Rooms[0].Room[i]: ", hotel.Rooms[0].Room[i])
        for (let j = 0; j < hotel.Rooms[0].Room[i].Boarding.length; j++) {
          console.log("hotel.Room[i].Boarding[j]).toLowerCase(): ", (hotel.Rooms[0].Room[i].Boarding[j].Title).toLowerCase(), "boarding.toLowerCase(): ", boarding.toLowerCase())
          return (hotel.Rooms[0].Room[i].Boarding[j].Title).toLowerCase() == boarding.toLowerCase()
        }
      }
    })
    console.log("this.HotelFiltrer: ", this.HotelFiltrer)
  }
  changeFromDate(event) {
    this.DashboardSendData.UpdateFromDate(this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd'))
  }
  changeToDate(event) {
    this.DashboardSendData.UpdateToDate(this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd'))

  }
}
