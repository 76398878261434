import { Injectable } from '@angular/core';
import { DataService } from './DataService';
import { SalleService } from './SalleService';
import { Salle } from '../models/salle.model';
import { OrganisateurService } from './OrganisateurService';
import { Organisateur } from '../models/organisateur.model';
import {  of  } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class MyInitService {
  constructor(
    private dataService: DataService,
    private salleService: SalleService,
    private organisateurService: OrganisateurService,

    ) {}

 //initialize(): Promise<any> {
    initialize(){
        console.log("initialize()")
        this.loadConfig()
    // Place your initialization logic here.
    //return this.loadConfig(); // Example: Load configuration data.
  }

  //private loadConfig(): Promise<any> {
    private loadConfig(){
    console.log("INITIALIZING")
    this.salleService.getList().subscribe(response => {
      console.log("ResponseGetListSalle: ", response)
      let salles =response.map(salle => new Salle(salle))
      this.dataService.setListSalle(salles)
      //this.dataService.globalData=of(response.map(salle => new Salle(salle)))
    },
      err => { console.log("salles/getall error", err); }
    );
    this.organisateurService.getList().subscribe(response => {
      console.log("ResponseGetListOrganisateur: ", response)
      let organisateurs =response.map(organisateur => new Organisateur(organisateur))
      this.dataService.setListOrganisateur(organisateurs)
      //this.dataService.globalData=of(response.map(organisateur => new Organisateur(organisateur)))
    },
      err => { console.log("organisateurs/getall error", err); }
    );
    //return Promise.resolve();
  }
}