import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    })
};
@Injectable({
    providedIn: 'root'
})
export class AccessService {
    private profilSubject = new BehaviorSubject<boolean>(true);
    profil$ = this.profilSubject.asObservable();





    setProfil(profil: boolean): void {
        this.profilSubject.next(profil);
      }
}