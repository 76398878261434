import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root',
})
export class SalleService {
  url_list_salle:string

  constructor(private http: HttpClient) {
    this.url_list_salle=environment.url_list_salle
  }
  getList():Observable<any>{   
    return this.http.get<any>(this.url_list_salle,httpOptions)  
  }
  addSalle(event) {
    return this.http.post<any>(this.url_list_salle, event ,httpOptions);
  }
  updateSalle(event) {
    return this.http.put<any>(this.url_list_salle + "/" + event.id, event,httpOptions);
  }
  deleteSalle(event) {
    return this.http.delete<any>(this.url_list_salle + "/" + event.id ,httpOptions);
  }
}