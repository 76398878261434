

export class Salle{
    id: number ;
    nom:string ;
    nb_max: string ;
  


    constructor(salle){
        this.id=salle.id ;
        this.nom=salle.nom ;
        this.nb_max=salle.nb_max;

    }
}

