import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuardService as AuthGuard } from './pages/pages/auth/auth-guard.service';

const routes: VexRoutes = [

  {
    path: 'programme',
    loadChildren: () => import('./pages/apps/programme/programme.module').then(m => m.ProgrammeModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },



  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: 'ConfirmRegistration',
    loadChildren: () => import('./pages/pages/auth/confirm-registration/confirm-registration.module').then(m => m.ConfirmRegistrationModule),
  },




  { path: '', redirectTo: '/login', pathMatch: 'full' },

  {
    path: 'apps',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],

    children: [
      {
        path: 'analytics',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'Dashboard',
        loadChildren: () => import('./pages/apps/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'information-inscription',
        loadChildren: () => import('./pages/apps/information-inscription/information-inscription.module').then(m => m.InformationInscriptionModule),
      },
      {
        path: 'liste-inscription',
        loadChildren: () => import('./pages/apps/liste-inscription/liste-inscription.module').then(m => m.ListeInscriptionModule),
      },
      {
        path: 'image-pup',
        loadChildren: () => import('./pages/apps/image-pup/image-pup.module').then(m => m.ImagePupModule),
      },
      {
        path: 'programme2',
        loadChildren: () => import('./pages/apps/programme2/programme2.module').then(m => m.Programme2Module),
      },
      {
        path: 'tarifs',
        loadChildren: () => import('./pages/apps/tarifs/tarifs.module').then(m => m.TarifsModule),
      },
      {
        path: 'packs',
        loadChildren: () => import('./pages/apps/packs/packs.module').then(m => m.PacksModule),
      },
      {
        path: 'groupe',
        loadChildren: () => import('./pages/apps/groupe/groupe.module').then(m => m.GroupeModule),
      },
      {
        path: 'upload-image',
        loadChildren: () => import('./pages/apps/upload-image/upload-image.module').then(m => m.UploadImageModule),
      },
      {
        path: 'atelier-table2',
        loadChildren: () => import('./pages/apps/atelier-table2/atelier-table2.module').then(m => m.AtelierTable2Module),
      },
      {
        path: 'code-promo',
        loadChildren: () => import('./pages/apps/code-promo/code-promo.module').then(m => m.CodePromoModule),
      },

      {
        path: 'admin',
        loadChildren: () => import('./pages/apps/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/apps/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'remarque',
        loadChildren: () => import('./pages/apps/remarque/remarque.module').then(m => m.RemarqueModule),
      },




      {
        path: 'inscription',
        loadChildren: () => import('./pages/apps/inscription/inscription.module').then(m => m.InscriptionModule),
      },

      {
        path: 'soumission',
        loadChildren: () => import('./pages/apps/soumission/soumission.module').then(m => m.SoumissionModule),
      },
      {
        path: 'comission',
        loadChildren: () => import('./pages/apps/comission/comission.module').then(m => m.ComissionModule),
      },
      {
        path: 'labo',
        loadChildren: () => import('./pages/apps/labo/labo.module').then(m => m.LaboModule),
      },
      {
        path: 'hebergement',
        loadChildren: () => import('./pages/apps/hebergement/hebergement.module').then(m => m.HebergementModule),
      },
      {
        path: 'conferencier',
        loadChildren: () => import('./pages/apps/conferencier/conferencier.module').then(m => m.ConferencierModule),
      },



      {
        path: 'conference',
        loadChildren: () => import('./pages/apps/conference/conference.module').then(m => m.ConferenceModule),
      },

      {
        path: 'badge',
        loadChildren: () => import('./pages/apps/badge/badge.module').then(m => m.BadgeModule),
      },



      {
        path: 'reservations',
        loadChildren: () => import('./pages/apps/reservations/reservations.module').then(m => m.ReservationsModule),
      },
      {
        path: 'event-table',
        loadChildren: () => import('./pages/apps/event-table/event-table.module').then(m => m.EventTableModule)
      },
      {
        path: 'manage-events',
        loadChildren: () => import('./pages/apps/manage-events/manage-events.module').then(m => m.ManageEventsModule)
      },
      {
        path: 'table-agent',
        loadChildren: () => import('./pages/apps/table-agent/table-agent.module').then(m => m.TableAgentModule)
      },
      {
        path: 'profil',
        loadChildren: () => import('./pages/apps/profil/profil.module').then(m => m.ProfilModule)
      },
      {
        path: 'participant',
        loadChildren: () => import('./pages/apps/participant/participant.module').then(m => m.ParticipantModule)
      },
      {
        path: 'speakers-table',
        loadChildren: () => import('./pages/apps/speakers-table/speakers-table.module').then(m => m.SpeakersTableModule)
      },
      {
        path: 'workshop-table',
        loadChildren: () => import('./pages/apps/workshop-table/workshop-table.module').then(m => m.WorkshopTableModule)
      },

      {
        path: 'historique-inscription',
        loadChildren: () => import('./pages/apps/historique-inscription/historique-inscription.module').then(m => m.HistoriqueInscriptionModule)
      },


      {
        path: 'presence',
        loadChildren: () => import('./pages/apps/presence/presence.module').then(m => m.PresenceModule)
      },
      {
        path: 'attestation',
        loadChildren: () => import('./pages/apps/attestation/attestation.module').then(m => m.AttestationModule)
      },
      {
        path: 'atelier-table',
        loadChildren: () => import('./pages/apps/atelier-table/atelier-table.module').then(m => m.AtelierTableModule)
      },
      {
        path: 'alert',
        loadChildren: () => import('./pages/apps/alert/alert.module').then(m => m.AlertModule)
      },
      {
        path: 'add-comment',
        loadChildren: () => import('./pages/apps/add-comment/add-comment.module').then(m => m.AddCommentModule)
      },
      {
        path: 'alert-badge',
        loadChildren: () => import('./pages/apps/alert-badge/alert-badge.module').then(m => m.AlertBadgeModule)
      },
      {
        path: 'quiz',
        loadChildren: () =>
          import('./pages/apps/quiz/quiz.module').then((m) => m.QuizModule),
      },
      {
        path: 'test_json',
        loadChildren: () =>
          import('./pages/apps/test-json/test-json.module').then((m) => m.TestJsonModule),
      },
      {
        path: 'changepass',
        loadChildren: () =>
          import('./pages/apps/changepass/changepass.module').then((m) => m.ChangePassModule),
      },
    ]
  },
  {
    path: 'pages',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'pricing',
        loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'invoice',
        loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
      },
      {
        path: 'error-404',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      },
      {
        path: 'error-500',
        loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
      }
    ]
  },
  {
    path: 'ui',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'components',
        loadChildren: () => import('./pages/ui/components/components.module').then(m => m.ComponentsModule),
      },
      {
        path: 'forms/form-elements',
        loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
        data: {
          containerEnabled: true
        }
      },
      {
        path: 'forms/form-wizard',
        loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
        data: {
          containerEnabled: true
        }
      },
      {
        path: 'icons',
        loadChildren: () => import('./pages/ui/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'page-layouts',
        loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
      },
    ]
  },
  {
    path: 'documentation',
    loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationModule),
  },
  {
    path: '**',
    loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  }
]
  ;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
