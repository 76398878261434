import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root',
})
export class OrganisateurService {
  url_list_organisateur:string

  constructor(private http: HttpClient) {
    this.url_list_organisateur=environment.url_list_organisateur
  }
  getList():Observable<any>{   
    return this.http.get<any>(this.url_list_organisateur,httpOptions)  
  }
  addOrganisateur(event) {
    return this.http.post<any>(this.url_list_organisateur, event ,httpOptions);
  }
  updateOrganisateur(event) {
    return this.http.put<any>(this.url_list_organisateur + "/" + event.id, event,httpOptions);
  }
  deleteOrganisateur(event) {
    return this.http.delete<any>(this.url_list_organisateur + "/" + event.id ,httpOptions);
  }
}