<div (mouseenter)="collapseOpenSidenav()"
     (mouseleave)="collapseCloseSidenav()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="vex-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center">
      <!-- Bouton à gauche -->
      <button 
              *ngIf="showCollapsePin$ | async"
              mat-icon-button
              (click)="toggleCollapse()">
          <mat-icon *ngIf="!collapsed" class="text-[1.5rem] w-[1.5rem] h-[1.5rem]" svgIcon="mat:menu"></mat-icon>
          <mat-icon *ngIf="collapsed" class="text-[1.5rem] w-[1.5rem] h-[1.5rem]" svgIcon="mat:close"></mat-icon>
      </button>
  
      <!-- Espace entre le bouton et l'image -->
      <div class="flex-grow"></div>
  
      <!-- Image à droite -->
      <img src="../assets/img/demo/dotcom-aurora.png" class="m-4"
           style="max-width: 125%; height: 304%; margin-right: -44px; margin-bottom: -29px; padding-right: 52px; margin-left: -17%;"/>
  
      <!-- Titre de la barre latérale (à gauche de l'image) -->
      <!-- <h2 style="padding-left: 0px;color: #6366F1;font-size: 31px;" class="vex-sidenav-toolbar__headline flex-auto">{{ title$ | async }}</h2> -->
  </div>
  
  
    
    <!--    <div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container">
      <div class="vex-sidenav-search relative"
           (click)="openSearch()"
           matRipple
           matRippleColor="var(--sidenav-item-ripple-color)">
        <mat-icon class="flex-none vex-sidenav-search__icon" svgIcon="mat:search"></mat-icon>
        <div class="flex-auto vex-sidenav-search__placeholder">Quick Search</div>
        <div class="flex-none vex-sidenav-search__keybind">Ctrl K</div>
      </div>
    </div> -->

    <div class="vex-sidenav-toolbar__divider"></div>
  </div>

  <vex-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>

   <!--  <div class="vex-sidenav-footer flex items-center justify-center opacity-20 px-6 py-6 select-none flex-none">
      <img [src]="imageUrl$ | async" alt="Logo" class="w-8 select-none"/>
    </div> 
    
     (click)="openProfileMenu(userProfileMenuOriginRef)"
         [class.vex-sidenav-user--open]="userMenuOpen$ | async"
    -->
  </vex-scrollbar>

  <div *ngIf="userVisible$ | async"
       class="vex-sidenav-user__container flex-none">
    <div 
    
    #userProfileMenuOriginRef
        
         class="vex-sidenav-user flex items-center"
         matRipple
         matRippleColor="var(--sidenav-item-ripple-color)"
         
         
         >
      <!-- <img alt="User Avatar" class="vex-sidenav-user__image flex-none" src="assets/img/avatars/1.jpg"/> -->
      <div class="vex-sidenav-user__content flex-auto">
        <div class="vex-sidenav-user__title">{{username}}</div>
      </div>
      <!-- <mat-icon class="vex-sidenav-user__dropdown-icon flex-none" svgIcon="mat:arrow_drop_down"></mat-icon> -->
    </div>
  </div>
</div>
