// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  urlstaticlist: '/list',
  //urlLogin: '/Login',
  //urlRegister: '/RegisterClient',
  urlUpdatePassword: '/ResetPassword',
  urlUpdateMappage: '/Mappage',
  urlSelectMappage: '/Mappageselect',
  urlReverseSelectMappage: '/Mappagereverseselect',
  urlListCountry: '/listcountry',
  urlConfirmRegistration: "/ConfirmRegistration",
  urlXmlout: '/XmlOut',
  urlAddAgency: '/AddAgency',
  urlUpdateAgencyInProd: '/UpdateAgencyInProd',
  urlUpdateAgencyStatus: '/UpdateAgencyStatus',
  urlGroups: '/Group',
  urlGroupChange: '/GroupChange',
  urlAgenceBygroup: '/AgenceByGroup',
  urlAdd_hotel: '/Hotel',
  urlget_hotel: '/Hotel',
  urlPut_hotel: '/Hotel',
  urlDelete_hotel: '/Hotel',
  urlGet_city: '/City',
  urlGet_country: '/Country',
  urlGet_facilitie: '/Facility',
  urlPost_facilitie: '/Facility',
  urlPut_facilitie: '/Facility',
  urlDelete_facilitie: '/Facility',
  urlPost_facilitie_id: '/AssignFacility',
  urlDashboard: '/Dashboard?clientid=21',
  urlGetListGroupe: '/Group',
  urlGetAgences: '/XmlOut',
  urlFileUpload: '/FileUpload',
  urllogos: '/logo',
  urlUser: "/User",
  urlGet_image: '/HotelImage/',
  urlPost_image: '/HotelImage',
  urlDelete_image: '/HotelImage/',
  urlupdatestatusUser: "/UserActivate",
  urlGet_hotel: '/Hotel',
  urlGet_Contart: '/Contrat',
  urlGet_RoomType: '/RoomType',
  url_post_contrat: '/Contrat',
  urlGet_Room: '/Room', /*ines@dotcom.tn Form array Period */
  url_post_room_contrat: '/RoomContrat', /*rb@dotcom.tn  api post room contrat*/
  url_get_arrangement: '/Arrangement',
  url_post_board_contrat: '/BoardContrat',
  url_post_periode_contrat: '/Period',
  url_Get_board: '/Arrangement',
  url_post_board: '/Arrangement/',
  url_put_board: '/Arrangement/',
  url_delete_board: '/Arrangement/',
  url_Get_country: '/Country',
  url_Post_country: '/Country',
  url_delete_country: '/Country/',
  url_Put_country: '/Country/',
  url_put_room: '/Room/',
  url_delete_room: '/Room/',
  url_post_room: '/Room/',
  urlGet_ville: '/City',
  urlpost_ville: '/City',
  url_put_ville: '/City/',
  url_delete_ville: '/City/',
  urlPostBaseRoomPrice: '/BasePrice',
  url_Get_Period: '/Period/',
  url_Get_Room: '/Room/',
  url_Get_RoomType: '/RoomType/',
  urlGet_board: '/Arrangement/',
  url_Get_Periode_Of_Contrat: '/PeriodParContrat',
  url_Get_Boards_Of_Contrat: '/BoardContratParContrat',
  url_Get_Room_Of_Contart: '/RoomContratParContrat',
  urlGet_ContartAutoComplete: '/ContratAutoComplete',
  url_Post_ChildPrice: '/ChildPrice',
  url_get_ChildPrice: '/ChildPrice/',
  url_Put_ChildPrices: '/ChildPrice/',
  url_Delete_ChildPrices: '/ChildPrice/',
  url_get_contrat: '/Contrat',
  url_Post_SpecialOffer: '/SpecialOffer',
  url_get_ChaineHotel: '/HotelChain',
  url_Post_booking: '/EarlyBooking',
  url_Post_DayPromotion: '/DayPromotion',
  url_Post_stay: '/LongStay',
  url_Post_HonneyMoon: '/HoneyMoon',
  url_Post_RoomPromotion: '/RoomPromotion',
  url_Post_PaxPromotion: '/PaxPromotion',
  url_Get_MinimumStay: '/MinimumStayTab/',
  url_Post_MinimumStay: '/MinimumStay',
  url_Get_WeekendDay: '/CalculateAll/',
  url_Post_weekendDay: '/WeekendDay',
  url_Post_StopSale: '/StopSale',
  url_Get_StopSale: '/StopSale',
  url_Post_Sale: '/StopSale',
  url_Get_ChildPrice_Of_Contart: '/ChildPrice/Contrat',
  url_Get_SpecialOffer_Of_Contart: '/SpecialOffer/Contrat',
  url_Get_EarlyBooking_Of_Contart: '/EarlyBooking/Offer',
  url_DayPromotion_Of_Contart: '/DayPromotion/Offer',
  url_LongStay_Of_Contart: '/LongStay/Offer',
  url_Get_HoneyMoonersSpo_Of_Contart: '/HoneyMoon/Offer',
  url_Get_RoomPromotion_Of_Contrat: '/RoomPromotion/Offer',
  url_Get_PaxPromotion_Of_Contrat: '/PaxPromotion/Offer',
  url_Get_WeekendDay_Of_Contrat: '/WeekendDay',
  url_Get_MinimumStay_Of_Contrat: '/MinimumStay/Offer',
  url_Get_StopSale_Of_Contrat: '/StopSale/Offer',
  url_Get_EarlyBooking: '/EarlyBooking',
  urlXmlin: '',
  urlmappagelist: '',
  urlReservations: '',
  urlAutoComplete: '',
  urlSession: '',
  urlSearch: '',
  urlTestXmlin: '',



  /****************************************************************** */

  url_Get_Reservation: 'https://events.dotcom.tn/events/Inscription',

  url_Post_Reservation: 'https://events.dotcom.tn/events/Inscription',
  url_Get_Recu_Paiement: 'https://events.dotcom.tn/events/Virement/',
  url_Priseencharge: 'https://events.dotcom.tn/events/PriseEnCharge/',
  url_Resume: 'https://events.dotcom.tn/events/Files/Resume/',
  url_Poster: 'https://events.dotcom.tn/events/Files/Poster/',
  url_Confirmer_Reservation: 'https://events.dotcom.tn/events/Confirmation/',
  url_Get_Voucher: 'https://events.dotcom.tn/events/Files/Voucher/ATP_voucher_',
  url_Get_Badge: 'https://events.dotcom.tn/events/Badge/ATP_badge_',
  urlLogin: 'https://events.dotcom.tn/events/PlatformLogin',
  urlRegister: 'https://events.dotcom.tn/events/register',

  urlResetRequest: 'https://events.dotcom.tn/events/ChangePassword',


  url_confirmer_payement_parespace: 'https://events.dotcom.tn/events/ConfirmerPayment',
  urlInsertInscription: 'https://events.dotcom.tn/events/insertListeInscription',
  urlCheckRegistration: 'https://events.dotcom.tn/events/checkregister_1002',
  urlPaiement: 'https://events.dotcom.tn/events/processPayments_1002',



  url_payementsurPlace: 'https://events.dotcom.tn/events/PaymentSurPlace',

  url_Annuler_Reservation: "https://events.dotcom.tn/events/Annulation/",
  url_getlist_conferencier: 'https://events.dotcom.tn/events/Submission',
  url_list_event: 'https://events.dotcom.tn/events/Event/',
  url_put_event: 'https://events.dotcom.tn/events/Events',





  url_list_agent: 'https://events.dotcom.tn/events/Agent/',
  url_list_participant: 'https://events.dotcom.tn/events/Participant/',
  url_list_presence: 'https://events.dotcom.tn/events/Presence/',
  url_list_workshop: 'https://events.dotcom.tn/events/Workshop/',

  url_list_salle: 'https://events.dotcom.tn/events/Station/',
  url_list_testjson: 'https://91.134.146.229:17341/apiB2B/test_json/',
  url_list_organisateur: 'https://events.dotcom.tn/events/Organisateur/',
  url_list_conference: 'https://events.dotcom.tn/events/Conference/',
  url_list_hebergement: 'https://events.dotcom.tn/events/Hebergement/',
  url_list_event_paiement: 'https://events.dotcom.tn/events/Event-Paiement/',
  url_list_submission_profil: 'https://events.dotcom.tn/events/SousmissionByUser',
  url_list_submission: 'https://events.dotcom.tn/events/Sousmission/',
  url_uploadresume: 'https://events.dotcom.tn/events/Files/UploadResume/',
  url_uploadposter: 'https://events.dotcom.tn/events/Files/UploadPoster/',
  url_confirm_submission: 'https://events.dotcom.tn/events/ConfirmResume/',
  url_refus_submission: "https://events.dotcom.tn/events/RefusResume/",
  url_PostAtelier: "https://events.dotcom.tn/events/Atelier",
  url_activate_atelier: "https://events.dotcom.tn/events/Atelier/activate",
  url_Put_Atelier: "https://events.dotcom.tn/events/Atelier",
  url_exportResume: "https://events.dotcom.tn/events/exportSoumissionPerTheme",

  //code
  url_PostCode: "https://events.dotcom.tn/events/Code",
  url_activate_code: "https://events.dotcom.tn/events/code/status/",
  url_list_Code: 'https://events.dotcom.tn/events/Code',
  //Payment
  url_PostPayment: "https://events.dotcom.tn/events/PaymentOnLine",
  //atelier2
  url_PostAtelier2: "https://events.dotcom.tn/events/atelier2",
  url_activate_atelier2: "https://events.dotcom.tn/events/atelier2/status",
  url_Put_Atelier2: "https://events.dotcom.tn/events/atelier2",
  url_list_workshop2: 'https://events.dotcom.tn/events/atelier2',

  //pack
  url_PostPack: "https://events.dotcom.tn/events/Addpack",
  url_Put_Pack: "https://events.dotcom.tn/events/pack",
  url_activate_pack: "https://events.dotcom.tn/events/pack/status",
  url_list_Pack: "https://events.dotcom.tn/events/packs",
  url_deletePack: "https://events.dotcom.tn/events/packs",

  //image
  url_addProduct: "https://events.dotcom.tn/events/AddPubs",
  url_PostImage: "https://events.dotcom.tn/events/ImageUpload",
  url_list_Pup: "https://events.dotcom.tn/events/Pubs",
  url_get_Pub_Image: 'https://events.dotcom.tn/events/Files/PubImage/',
  url_get_Image: 'https://events.dotcom.tn/events/findPubById',

  //Labo

  url_list_labo: 'https://events.dotcom.tn/events/getAllLabo',
  url_add_labo: 'https://events.dotcom.tn/events/AddLabo',
  url_put_labo: 'https://events.dotcom.tn/events/UpdateLabo',
  url_delete_labo: 'https://events.dotcom.tn/events/deleteLabo',



  /// url profile 

  url_get_profile: 'https://events.dotcom.tn/events/profilDetails',



  //url image background
  url_PostImageBackGround: 'https://events.dotcom.tn/events/EventImageUpload',
  url_get_ImageBackground: 'https://events.dotcom.tn/events/findEvent  ',
  url_get_Background_Image: 'https://events.dotcom.tn/events/Files/EventImage/',

  //Groupe
  url_list_Groupe: 'https://events.dotcom.tn/events/GetGroup',
  url_activate_groupe: 'https://events.dotcom.tn/events/StatusGroupe',
  url_PostGroupe: 'https://events.dotcom.tn/events/AddGroupe',
  url_Put_Groupe: 'https://events.dotcom.tn/events/UpdateGroupe',
  url_deleteGroupe: 'https://events.dotcom.tn/events/DeleteGroup',

  //hebergement
  url_list_Hebergement: 'https://events.dotcom.tn/events/Hebergement/',
  url_PostHebergement: 'https://events.dotcom.tn/events/Hebergement',
  url_Put_Hebergement: 'https://events.dotcom.tn/events/Hebergement',



  ///remarque
  url_list_Remarque: "https://events.dotcom.tn/events/remarque",
  url_PostRemarque: "https://events.dotcom.tn/events/remarque",
  url_Put_Remarque: "https://events.dotcom.tn/events/remarque",
  url_deleteRemarque: "https://events.dotcom.tn/events/remarque",

  // image Poster 
  url_PosterImage: "https://events.dotcom.tn/events/Files/PosterImage/",



  url_add_agent: "https://events.dotcom.tn/events/Agent",
  url_changepassword: 'https://events.dotcom.tn/events/UpdatePassword',
  url_get_speaker: "https://events.dotcom.tn/events/Conferencier",
  url_post_speaker: "https://events.dotcom.tn/events/Conferencier",
  url_put_speaker: "https://events.dotcom.tn/events/Conferencier",
  url_delete_speaker: "https://events.dotcom.tn/events/Conferencier",
  url_get_conference: "https://events.dotcom.tn/events/Conference",
  url_post_conference: "https://events.dotcom.tn/events/Conference",
  url_put_conference: "https://events.dotcom.tn/events/Conference",
  url_delete_conference: "https://events.dotcom.tn/events/Conference",
  url_Post_badge: "https://events.dotcom.tn/events/AMFBadge",
  url_get_badge_: 'https://events.dotcom.tn/events/Files/AMF_Badge/AMF_badge_'
  , url_put_comment: 'https://events.dotcom.tn/events/Comment',



  url_generate_badge: "https://events.dotcom.tn/events/ATPBadge",
  url_get_badge: "https://events.dotcom.tn/events/Files/ATP_Badge/",


  // send price 


  urlsendPriceData: 'https://events.dotcom.tn/events/calculPrice',




  url_get_send_email: 'https://events.dotcom.tn/events/SendEmail',
  url_Annuler_Reservation_: "https://events.dotcom.tn/events/Reservation/delete",
  url_Post_Price: "https://events.dotcom.tn/events/calculPrice",
  url_Put_inscrie: "https://events.dotcom.tn/events/Reservation/update",
  url_get_archive: "https://events.dotcom.tn/events/Archive",
  url_post_soumission: "https://events.dotcom.tn/events/Soumission",
  url_post_poster: "https://events.dotcom.tn/events/Poster",
  url_Get_soumissionbyid: "https://events.dotcom.tn/events/findResumeById",
  url_Get_soumission: "https://events.dotcom.tn/events/getAllResumePoster",
  url_confirm_resumer: "https://events.dotcom.tn/events/activeResume",
  url_put_resumer: "https://events.dotcom.tn/events/updateSoumission",
  url_put_poster: "https://events.dotcom.tn/events/Poster",
  url_post_date_resumer: "https://events.dotcom.tn/events/DeadlinesSoumission",
  url_post_date_poster: "https://events.dotcom.tn/events/DeadlinesPoster",
  url_get_admin: "https://events.dotcom.tn/events/getAllAdmin",
  url_post_admin: "https://events.dotcom.tn/events/AddAdmin",
  url_put_admin: "https://events.dotcom.tn/events/UpdateAdmin",
  url_delete_admin: "https://events.dotcom.tn/events/deleteAdmin",

  url_delete_pup: "https://events.dotcom.tn/events/deletePub",
  url_put_pub: "https://events.dotcom.tn/events/UpdatePubs",
  url_active_pub: "https://events.dotcom.tn/events/activatePub",



  url_put_presence: "https://events.dotcom.tn/events/presence_congres",
  urlFileUploadImage: "https://events.dotcom.tn/events/imagePosterUpload",
  url_activate_inscription: "https://events.dotcom.tn/events/updateInscriptionStatus",

  //url_confirmer_poster:"https://events.dotcom.tn/events/activePoster",

  url_refuse_resume: "https://events.dotcom.tn/events/desActiveResume",


  url_data_dashboard: "https://events.dotcom.tn/events/dashboardStaticData",
  url_data_dashboard_atelier_chart: "https://events.dotcom.tn/events/dashboardPackAtelier"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
