import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../../../interfaces/navigation-item.interface';
import { dropdownAnimation } from '../../../animations/dropdown.animation';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation.service';
import { AccessService } from '../../../../app/pages/services/AccessService';


@UntilDestroy()
@Component({
  selector: 'vex-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [dropdownAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavItemComponent implements OnInit, OnChanges {

  @Input() item: NavigationItem;
  @Input() level: number;
  isOpen: boolean;
  isActive: boolean;
  response;
  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;

  constructor(private router: Router,
    private cd: ChangeDetectorRef,
    private navigationService: NavigationService,
    private accessService: AccessService) { }

  profil: boolean = true


  @HostBinding('class')
  get levelClass() {
    return `item-level-${this.level}`;
  }

  ngOnInit() {
    let starter = (this.navigationService.items[0]) as NavigationSubheading
    this.response = JSON.parse(sessionStorage.getItem('reponse'))
    console.log("****response storage ******", this.response)
    this.cd.detectChanges()
    if (this.response != null) {
      if (this.response.role == "user") {
        starter.children[starter.children.findIndex(p => p.label == "Profile")]["active"] = true

        starter.children[starter.children.findIndex(p => p.label == "Programme")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Inscriptions")]["active"] = true

        starter.children[starter.children.findIndex(p => p.label == "Soumission")]["active"] = true
        //  starter.children[starter.children.findIndex(p=>p.label=="Soumissions")]["active"]=true
        starter.children[starter.children.findIndex(p => p.label == "Tarifs")]["active"] = true

        // starter.children[starter.children.findIndex(p=>p.label=="Badge")]["active"]=true
        starter.children[starter.children.findIndex(p => p.label == "Profile")]["active"] = true

      }




      else if ((this.response.role == "admin") && (this.response.login == "PurEvents2024@dotcom.tn")) {
        starter.children[starter.children.findIndex(p => p.label == "Admin")]["active"] = true

        starter.children[starter.children.findIndex(p => p.label == "Dashboard")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Events Table")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Tarifs")]["active"] = true

        // starter.children[starter.children.findIndex(p=>p.label=="Events")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Liste Inscriptions")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Laboratoire")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Télecharger Images")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Groupe des ateliers")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Soumission")]["active"] = true

        starter.children[starter.children.findIndex(p => p.label == "Ateliers")]["active"] = true
        // starter.children[starter.children.findIndex(p => p.label == "Badges")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Programme")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Presences")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "CodePromo")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Packs")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Agents")]["active"] = true
        // starter.children[starter.children.findIndex(p=>p.label=="Conferences")]["active"]=true
        starter.children[starter.children.findIndex(p => p.label == "Publicité")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Ateliers")]["active"] = true
        // starter.children[starter.children.findIndex(p => p.label == "Inscriptions")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Hébergement")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Remarque")]["active"] = true








      }
      else if (this.response.role == "admin") {

        starter.children[starter.children.findIndex(p => p.label == "Dashboard")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Tarifs")]["active"] = true

        starter.children[starter.children.findIndex(p => p.label == "Liste Inscriptions")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Soumission")]["active"] = true

        starter.children[starter.children.findIndex(p => p.label == "Badges")]["active"] = false
        starter.children[starter.children.findIndex(p => p.label == "Programme")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Presences")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Agents")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "Profile")]["active"] = true









      }

      else if (this.response.role == "labo") {
        starter.children[starter.children.findIndex(p => p.label == "Programme")]["active"] = true
        starter.children[starter.children.findIndex(p => p.label == "CodePromo")]["active"] = true


        starter.children[starter.children.findIndex(p => p.label == "Profile")]["active"] = true




      }

      // else {
      //   starter.children[starter.children.findIndex(p => p.label == "Admin")]["active"] = true






      // }

    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(() => this.onRouteChange());

    this.navigationService.openChange$.pipe(
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(item => this.onOpenChange(item));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('item') && this.isDropdown(this.item)) {
      this.onRouteChange();
      this.cd.detectChanges()
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
    this.cd.markForCheck();
  }

  onOpenChange(item: NavigationDropdown) {
    if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
      return;
    }

    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      return;
    }

    if (this.item !== item) {
      this.isOpen = false;
      this.cd.markForCheck();
    }
  }

  onRouteChange() {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    }
  }

  isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown) {
    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    return parent.children
      .filter(child => this.isDropdown(child))
      .some(child => this.isChildrenOf(child as NavigationDropdown, item));
  }

  hasActiveChilds(parent: NavigationDropdown) {
    return parent.children.some(child => {
      if (this.isDropdown(child)) {
        return this.hasActiveChilds(child);
      }

      if (this.isLink(child) && !this.isFunction(child.route)) {
        return this.router.isActive(child.route as string, false);
      }
    });
  }

  isFunction(prop: NavigationLink['route']) {
    return prop instanceof Function;
  }
}
