// data.service.ts
import { Injectable } from '@angular/core';
import { Salle } from '../models/salle.model';
import { Organisateur } from '../models/organisateur.model';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    globalData: any; // This can be any data type you need
    list_salles: Salle[]
    list_organisateurs: Organisateur[]
    constructor() {}

    getListSalle(){
        return this.list_salles
    }
    setListSalle(salles:Salle[]){
        this.list_salles=salles
    }

    getListOrganisateur(){
        return this.list_organisateurs
    }
    setListOrganisateur(organisateurs:Organisateur[]){
        this.list_organisateurs=organisateurs
    }
}