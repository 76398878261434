

export class Organisateur{
    id: number ;
    nom_organisation:string ;
    type_organisation:string;
    mail_organisation:string;
    telephone_organisation:string;
    nom_contact:string;
    prenom_contact:string;
    telephone_contact:string;
    mail_contact:string;
    fonction_contact:string;


    constructor(organisateur){
        this.id=organisateur.id ;
        this.nom_organisation=organisateur.nom_organisation ;
this.type_organisation=organisateur.type_organisation ;
this.mail_organisation=organisateur.mail_organisation ;
this.telephone_organisation=organisateur.telephone_organisation ;
this.nom_contact=organisateur.nom_contact ;
this.prenom_contact=organisateur.prenom_contact ;
this.telephone_contact=organisateur.telephone_contact ;
this.mail_contact=organisateur.mail_contact ;
this.fonction_contact=organisateur.fonction_contact ;

    }
}

